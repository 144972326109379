<template>
    <admin-default v-slot:admin>
        <div class="application">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Disbursement Details</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{ user.name }}</h1>
                    </div>
                </div>
            </div>

            <!--NAV-->
            <div class="navigation">
                <div class="container container-resize--1">
                    <nav class="application-nav">
                        <ul class="application-nav__lists">
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Personal Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Employment Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Loan Details</a>
                            </li>
                            <li class="application-nav__list">
                                <a href="#" class="application-nav__item">Disbursement Details</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="container container-resize">
                <div class="row mt-5">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="have" class="form__label">Do you have an existing loan?</label>
                            <select class="form-control form__input form__input--small" id="have">
                                <option></option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="much" class="form__label">If yes, how much monthly?</label>
                            <input type="text" class="form-control form__input margin-bottom" id="much" placeholder="NGN">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="account" class="form__label">Account Name</label>
                            <input type="text" class="form-control form__input margin-bottom" id="account" placeholder="Idemudia Precious">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="number" class="form__label">Account Number</label>
                            <input type="text" class="form-control form__input margin-bottom" id="number">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="bank" class="form__label">Bank Name</label>
                            <select class="form-control form__input form__input--small" id="bank">
                                <option>Zenith Bank</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="bvn" class="form__label">BVN</label>
                            <input type="text" class="form-control form__input margin-bottom" id="bvn" placeholder="NGN">
                        </div>
                    </div>
                </div>

                <div class="button--box">
                    <button type="button" class="btn button button-cta cta">Save</button>
                    <button type="button" class="btn button button-cta cta">Continue</button>
                    <button type="button" class="btn button btn-outline-primary button-outline outline-1">Back</button>
                </div>
            </div>
        </div>
    </admin-default>
</template>

<script>

    import AdminDefault from "../navigate/AdminDefault";
    export default {
        name: "Disbursement",
        components: {AdminDefault}
    }
</script>

<style scoped>

</style>